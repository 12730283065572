<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Create',
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
};
</script>

<style lang="less">
@import '@/assets/lessjs/variables.less';

.view {
  width: 100%;
  background-color: white;
  color: black;
  min-height: 100vh;
}

.main {
  flex: 1;
  overflow: auto;
  padding: 80px 0;

  .container {
    height: 100%;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: darken(@highlight, 10%) !important;
  background-color: @highlight !important;
}

h4 {
  margin-bottom: 0.8em !important;
}
</style>
